require('dotenv').config();

/* eslint-disable max-len */

export const port = process.env.PORT || 3000;
export const host = process.env.WEBSITE_HOSTNAME || `localhost:${port}`;
export const url = 'https://www.arahrides.com';
export const sitename = 'Arahrides';

// default locale is the first one
export const locales = ['en-US', 'es', 'it-IT', 'fr-FR', 'pt-PT', 'ar'];

export const databaseUrl = process.env.DATABASE_URL;

// Listing Photos Upload Directory
export const fileuploadDir = process.env.FILEUPLOAD_DIR || './images/upload/';

// Home Logo upload directory
export const homelogouploadDir = process.env.LOGOUPLOAD_DIR || './images/homeLogo/';

// Home page Banner upload directory
export const banneruploadDir = process.env.BANNER_UPLOAD_DIR || './images/banner/';
export const getBanneruploadDir = process.env.GET_BANNER_UPLOAD_DIR || '/images/banner/';

// User Profile Photos Upload Directory
export const profilePhotouploadDir = process.env.PROFILE_PHOTO_UPLOAD_DIR || './images/avatar/';

//Document Upload
export const documentuploadDir = process.env.FILEUPLOAD_DIR || './images/document/';

// Location upload directory
export const locationuploadDir = process.env.LOCATION_UPLOAD_DIR || './images/popularLocation/';

// Get Popular Location directory
export const getPopularLocationDir = process.env.GET_LOCATION_DIR || '/images/popularLocation/';

// Homepage images
export const homebanneruploadDir = process.env.HOME_BANNER_UPLOAD_DIR || './images/home/';
export const getHomebanneruploadDir = process.env.GET_HOME_PAGE_BANNER_DIR || '/images/home/';

// claim images
export const claimImagesUploadDir = process.env.CLAIM_IMAGES_UPLOAD_DIR || './images/claims/';
export const claimImageDir = process.env.CLAIM_IMAGE_DIR || '/images/claims/';

//favicon dir
export const faviconUploadDir = process.env.FAVICON_UPLOAD_DIR || './images/favicon/';
export const getFavIconDir = process.env.GET_FAVICON_DIR || '/images/favicon/';

// whyHostUploadDir	
export const whyHostUploadDir = process.env.WHYHOST_UPLOAD_DIR || './images/whyhost/';
export const getWhyHostDir = process.env.GET_WHYHOST_DIR || '/images/whyhost/';

// Logo upload directory
export const logouploadDir = process.env.LOGOUPLOAD_DIR || './images/logo/';
export const getLogoDir = process.env.GET_LOGO_DIR || '/images/logo/';

export const analytics = {
  // https://analytics.google.com/
  google: {
    trackingId: 'G-LZEK933J89',
  },
};

export const googleMapAPI = 'AIzaSyDzF5PdFV4ux5pFMnh7B50eRrxA6EeCvm4';
export const googleMapServerAPI = process.env.GOOGLE_MAP_SERVER_API;

export const payment = {

  paypal: {
    returnURL: `${url}${process.env.PAYPAL_RETURN_URL}`,
    cancelURL: `${url}${process.env.PAYPAL_CANCEL_URL}`,
    redirectURL: {
      success: `${url}${process.env.PAYPAL_SUCCESS_REDIRECT_URL}`,
      cancel: `${url}${process.env.PAYPAL_CANCEL_URL}`
    },
    versions: {
      versionOne: '/v1',
      versionTwo: '/v2'
    },
    token_url: '/oauth2/token',
    payment_url: '/checkout/orders',
    capture_url: '/capture',
    payout_url: '/payments/payouts',
    refund: '/refund',
    refund_capture: '/payments/captures/'
  },

  stripe: {
    secretKey: process.env.STRIPE_SECRET, /* From ENV */
    //publishableKey: 'pk_test_51O8nqpGa3KLWvukWPlNPvcc8SC72EpSq4iFCC5RiN2nttKR9iMjd6mKguYA1yIBkwJ6h5dyrmhJJF2rxNHHRGLmB0052vNr7nJ'
	  publishableKey: 'pk_live_51O8nqpGa3KLWvukW67tTrbKFW7dmWrqfQdLbXn6LLIA5cS2qoIbjNAQ3kDDc9HLKhb31WbFgvwO33nmzQf5iUR41008CU4O1AN'
  }

};

// site key for google recaptcha
export const googleCaptcha = {
  sitekey: '6Le0DxUpAAAAAJapB0opa0fIc54YMQOh4MRzKwWj'
};

export const auth = {
  jwt: { secret: process.env.JWT_SECRET },

  redirectURL: {
    login: process.env.LOGIN_URL || '/dashboard',
    verification: process.env.LOGIN_URL || '/user/verification',
    userbanned: process.env.USER_BANNED_URL || '/userbanned',
    returnURLDeletedUser: process.env.DELETE_USER_URL || '/userbanned'
  },

  // https://developers.facebook.com/ IT IS REMOVED ON THE FUNCTIONING CODE. 
  facebook: {
    returnURL: process.env.FACEBOOK_CLIENT_URL || `${url}/login/facebook/return`,
  },

  // https://cloud.google.com/console/project
  google: {
    returnURL: process.env.GOOGLE_CLIENT_URL || `${url}/login/google/return`,
  }
};
