import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector, FieldArray, change, untouch } from 'redux-form';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';

import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import {
  FormGroup,
  ControlLabel,
  Button,
  Row,
  Col,
} from 'react-bootstrap';
import s from './ListPlaceStep1.css';
import cs from '../commonStyle.css';

import SidePanel from './SidePanel';
import FooterButton from './FooterButton';

// Locale
import messages from '../../locale/messages';
import validateStep3 from './validateStep3';
import updateStep3 from './updateStep3';

//images
import infoIcon from '/public/SiteIcons/priceHoverIcon.svg';
import locationIcon from '/public/SiteIcons/locationIdea.svg';
import CommonFormComponent from '../CommonField/CommonFormComponent';
import { normalizePrice } from '../EditProfileForm/normalizePhone';

class LocationPickupDrop extends Component {

  static propTypes = {
    previousPage: PropTypes.any,
    nextPage: PropTypes.any,
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true,
      isButtonShow: true,
      locations: [{ location: '', locationPrice: '' }], // Initial section
    };
  }

  componentDidMount() {
    const { valid } = this.props;
    if (valid) {
      this.setState({ isDisabled: false });
    } else {
      this.setState({ isDisabled: true });
    }
  }

  UNSAFE_componentWillMount() {
    const { valid } = this.props;
    if (valid) {
      this.setState({ isDisabled: false });
    } else {
      this.setState({ isDisabled: true });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { valid } = nextProps;
    if (valid) {
      this.setState({ isDisabled: false });
    } else {
      this.setState({ isDisabled: true });
    }
  }

  handleAddSection = () => {
    const { locations } = this.state;
    const { formValues, change, extraGuest, sectionsData, touch, untouch } = this.props;
    this.setState({ locations: [...locations, { location: '', locationPrice: '' }] });
    let formSectionsData = [];
    if (sectionsData) {
      formSectionsData = [...sectionsData];
      formSectionsData.unshift({
        location: "",
        locationPrice: ""
      });
    } else {
      formSectionsData.push({
        location: "",
        locationPrice: ""
      });
    }
    change('locations', formSectionsData);

  };

  handleRemoveSection = index => {
    const { change, sectionsData, untouch } = this.props;
    const { locations } = this.state;
    let formSectionsData = [];
    locations.splice(index, 1);
    this.setState({ locations: locations });

    if (sectionsData) {
      formSectionsData = sectionsData;
      formSectionsData.splice(index, 1);
      if (formSectionsData.length == 0) {
        change('locations', null);
      } else {
        change('locations', formSectionsData);
      }
    }
  };

  render() {
    const { handleSubmit, nextPage, previousPage, formPage, step, availableCurrencies, locationPickupDrop, sectionsData, initalLocationsData } = this.props;
    const { formatMessage } = this.props.intl;
    const { isDisabled, isButtonShow } = this.state;
    return (
      <div className={cx(s.stepGrid, 'stepGridRTL')}>
        <SidePanel
          title={formatMessage(messages.step3Heading)}
          landingContent={formatMessage(messages.setPricingpickupDrop)}
        />
        <form onSubmit={handleSubmit}>
          <div className={s.landingMainContent}>
            <h3 className={cx(cs.commonContentText, cs.spaceBottom3)}><FormattedMessage {...messages.locationDesc} /></h3>
            {isButtonShow && <><h3 className={cx(cs.commonContentText, cs.spaceBottom3)}><FormattedMessage {...messages.setPricingpickupDropDescription} /></h3>
              <Button bsStyle="primary" onClick={this.handleAddSection} className={cx(cs.btnPrimary, cs.spaceBottom3)}>
                <FormattedMessage {...messages.addLocation} />
              </Button></>}
            <FieldArray name="locations" component={this.renderSections} sectionsDataArray={sectionsData} allLocations={locationPickupDrop ? locationPickupDrop : initalLocationsData} />
          </div>
          <FooterButton
            nextPage={nextPage}
            previousPage={previousPage}
            previousPagePath={"pricing"}
            nextPagePath={"discount"}
            formPage={formPage}
            step={step}
            isDisabled={isDisabled}
          />
        </form>
      </div>
    );
  }

  renderSections = ({ fields, sectionsDataArray, allLocations }) => {
    const { handleSubmit, nextPage, previousPage, formPage, step, availableCurrencies, locationPickupDrop, sectionsData, initalLocationsData } = this.props;
    const { formatMessage } = this.props.intl;
    const { isDisabled, isButtonShow } = this.state;
    let locations = locationPickupDrop ? locationPickupDrop : initalLocationsData
    let unmatchedLocations = allLocations?.filter(location => {
      return !sectionsDataArray?.some(section => section?.location == location?.id);
    });
    if (unmatchedLocations?.length <= 0 || sectionsDataArray?.length >= 38) {
      this.setState({ isButtonShow: false });
    } else {
      this.setState({ isButtonShow: true });
    }
    return (
      <div>
        {fields.map((section, index) => {
          let data = locations?.find(val => val?.id == sectionsDataArray[index]?.location)
          return (

            <div key={index} className={s.sectionContainer}>
              <Button onClick={() => fields.remove(index)} className={cx(s.removeIconBtn, "removeIconBtnRTL")}>
                X
              </Button>
              <Row className={s.locationRow}>
                <Col xs={12} sm={12} md={12} lg={7}>
                  <FormGroup>
                    <Field
                      name={`${section}.location`}
                      component={CommonFormComponent}
                      inputClass={cx(s.formControlSelect, s.jumboSelect)}
                      isLocation={true}
                      value={data?.id}
                    >
                      <option value={data?.id ? data?.id : ""}>
                        {data?.location ? data?.location : formatMessage(messages.selectYourLocation)}
                      </option>
                      {
                        unmatchedLocations?.map((places, key) => {
                          if (places.isEnable == true) {
                            return <option key={key} value={places?.id}>{places?.location}</option>
                          }
                        })
                      }
                    </Field>
                  </FormGroup>
                </Col>
                <Col xs={12} sm={12} md={12} lg={4}>
                  <FormGroup className={cx(s.noMargin)}>
                    <Field
                      name={`${section}.locationPrice`}
                      type="text"
                      component={CommonFormComponent}
                      label={formatMessage(messages.price)}
                      inputClass={cx(s.formControlInput, s.jumboSelect, s.formControlInputMaxWidth)}
                      normalize={normalizePrice}
                      isLocation={true}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>

          )
        })}
      </div>
    );
  };
}

const selector = formValueSelector('ListPlaceStep3');
LocationPickupDrop = reduxForm({
  form: 'ListPlaceStep3',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: validateStep3,
  onSubmit: updateStep3
})(LocationPickupDrop);

const mapState = (state) => ({
  listingFields: state.listingFields.data,
  availableCurrencies: state.currency.availableCurrencies,
  locationPickupDrop: selector(state, 'locationPickupDrop'),
  sectionsData: selector(state, 'locations'),
  initalLocationsData: selector(state, 'locationsData')
});

const mapDispatch = {
  change
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(LocationPickupDrop)));